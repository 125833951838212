import React from 'react';
import { LuHome, LuUser, LuLightbulb, LuLayers, LuPhone } from "react-icons/lu";
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';   
import Animation from './lottiefiles/skills.lottie'; 

const Skills = () => {
  return (
    <div className='md:p-22 md:pt-24 p-8 flex justify-between md:pb-22 md:px-40 md:h-full select-none bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] bg-[white] md:mt-0 mt-6 pt-16'>
        <div className=''>
            <div className='md:w-6/12'>
                <div className='md:w-28 md:h-7 rounded-full w-20 h-5 bg-black md:px-1 space-x-3.5 mb-6'>
                    <LuLightbulb className='md:inline-block text-white font-semibold relative md:left-1.5 hidden scale-50 md:scale-100 md:-top-px' size={22}/>
                    <span className='text-white font-poppins text-1xl md:text-xs font-normal md:font-medium relative md:-left-0 -left-0 md:-top-0 -top-1 md:pb-0'> 不讲李</span>
                </div>
            </div>
            <div>
                <h1 className='font-poppins text-2xl font-medium'>收藏的 <span className='bg-gradient-to-r from-[#833be7cb] to-[#5521c5] bg-clip-text text-transparent'>域名</span><br className='md:block hidden'/> 和目前运营的<span className='md:hidden block'> </span><span>网站</span></h1>
                <hr className='md:w-44 w-32 md:mb-0 mb-4 h-3 mt-3 md:ml-0 lg:mt-4 bg-gradient-to-r from-[#bf77eced] to-[#c580f0c8]'></hr>
            </div>
            <div>
                <div>
                    <h1 className='md:font-semibold font-bold md:text-2xl md:pt-1.5 md:inline-block md:mr-5 md:mb-0 mb-2 md:mt-0 mt-2'>我运营的网站</h1>

                    <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                        <img src='https://domain.bf/assets/nicbn.e38040e9.jpeg' alt="git" style={{ width: 45, height: 45 }} className='inline md:pb-3 md:mr-1 mr-2'/>
                    </a>

                    <a href="https://whois.ls/" target="_blank" rel="noopener noreferrer">
                        <img src='https://domain.bf/assets/whois.9773d115.jpeg' alt="github" style={{ width: 45, height: 45 }} className='inline md:pb-3 md:mr-1 mr-2'/>
                    </a>

                    <a href="https://china.tn/" target="_blank" rel="noopener noreferrer">
                        <img src='https://wubaohu.com/wp-content/uploads/2024/08/freecompress-IMG_9142.jpeg' alt="vim" style={{ width: 45, height: 45 }} className='inline md:pb-3 md:mr-1 mr-2'/>
                    </a>

                    <a href="https://met.as/" target="_blank" rel="noopener noreferrer">
                        <img src='https://domain.bf/assets/domain.90dfd105.jpeg' alt="flask" style={{ width: 45, height: 45 }} className='inline md:pb-3 md:mr-1 mr-2'/>
                    </a>

                    <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                        <img src='https://domain.bf/assets/domain.90dfd105.jpeg' alt="linux" style={{ width: 45, height: 45 }} className='inline md:pb-3 md:mr-1 mr-2'/>
                    </a>
                    <a href="https://dev.ug/" target="_blank" rel="noopener noreferrer">
                        <img src='https://domain.bf/assets/devug.6cad7cb8.jpeg' alt="linux" style={{ width: 45, height: 45 }} className='inline md:pb-3 md:mr-1 mr-2'/>
                    </a>             
                </div>

                <div>
                    <h1 className='md:font-semibold font-bold md:text-2xl md:pt-1.5 md:inline-block md:mr-5 md:mb-0 mb-2 md:mt-0 mt-2'>我收藏的部分域名</h1>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                NIC.BN
                            </span>
                        </a>
                    </div>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
                                CXL.NET
                            </span>
                        </a>
                    </div>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                                MET.AS
                            </span>
                        </a>
                    </div>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                F.AF
                            </span>
                        </a>
                    </div>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                L.KE
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                GAME.KG
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                AI.KN
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                SALE.VG
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                BUG.KZ
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                DEV.UG
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                TOP.VG
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                RULE.ML
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://nic.bn/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                HELLO.UY
                            </span>
                        </a>
                    </div>
                </div>

                <div>
                    <h1 className='md:font-semibold font-bold md:text-2xl md:pt-1.5 md:inline-block md:mr-5 md:mb-0 mb-2 md:mt-0 mt-2'>我出售的部分域名</h1>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                NET.UG
                            </span>
                        </a>
                    </div>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
                                K.AF
                            </span>
                        </a>
                    </div>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                                0.SD
                            </span>
                        </a>
                    </div>

                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                E.AF
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                U.NF
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                Y.TD
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                V.AF
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                Q.AF
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                CHINA.BF
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                BANK.BF
                            </span>
                        </a>
                    </div>
                    <div className='inline-flex items-center rounded-full h-7 bg-black md:px-1 px-2 space-x-3.5 mb-6'>
                        <a href="https://domain.bf/" target="_blank" rel="noopener noreferrer">
                            <span className="inline-block md:pb-3 md:mr-1 mr-2 text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-cyan-600">
                                DOMAIN.YT
                            </span>
                        </a>
                    </div>
                </div>

                <div>
                    <h1 className='md:font-semibold font-bold md:text-2xl md:pt-1.5 md:inline-block md:mr-5 md:mb-0 mb-2 md:mt-0 mt-2'>更多域名信息查看</h1>

                    <a href="https://www.domain.bf/" target="_blank" rel="noopener noreferrer">
                        <span className="inline-block md:pb-3 md:mr-1 mr-2 text-3xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-purple-500">
                            DOMAIN.BF
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <div className='hidden lg:block'>
            <DotLottiePlayer
                src={Animation}
                autoplay
                loop
            >
            </DotLottiePlayer>
        </div>
    </div>
  )
}
export default Skills;
