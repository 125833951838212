import React, { useState } from 'react';
import { FaTwitter, FaWhatsapp, FaTelegramPlane, FaEnvelope, FaWeixin } from "react-icons/fa";
import Modal from 'react-modal';
import Resume from './resume';
import Frontpageanimation from './frontpageanimation';
import Github from "./github";

// For accessibility reasons, bind modal to your app element
Modal.setAppElement('#root');

const Content = () => {  // 修改函数名为大写开头
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='md:w-full flex justify-between bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] bg-[white] md:mt-0 mt-6'>
      <div className='min-h-screen md:px-36 md:py-16 md:pb-4 px-8 py-12 bg-[white] select-none md:w-6/12 md:pr-0 bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]'>
        <div className='text-center'>
          <br />
          <h1 className='text-left font-medium text-7xl md:text-8xl font-rubix bg-gradient-to-r from-[#833be7cb] to-[#5521c5] bg-clip-text text-transparent'>不讲李</h1>
          <h1 className='text-left md:pb-6 pt-2 md:pt-0 pb-7 leading-10 font-semibold text-6xl md:text-7xl font-rubix'>I am Lee</h1>
          <hr className='md:w-24 w-20 md:mb-0 mb-4 h-3 bg-gradient-to-r from-[#c580f0ed] to-[#c580f0c8]'></hr>
        </div>
        <div className='text-[rgb(15,23,42)] text-center'>
          <h1 className='text-left md:pt-8 text-2xl font-poppins font-normal'>
            少时先锋少先队员，长时社会主义接班人。
            <span className='text-left md:pt-8 text-3xl font-semibold font-rubix'>
              时刻准备着 <br /> 努力做共产主义事业接班人
            </span>
          </h1>
        </div>
        <div className="flex md:p-6 pt-4 space-x-2.5 w-full md:pt-8 md:pb-8">
          {/* WeChat Icon with QR Code Modal */}
          <div className="relative">
            <button
              onClick={openModal}
              className='pl-2 h-10 w-10 text-center border pt-2 rounded-md border-black hover:bg-[#c580f023] transition-all duration-500 active:bg-zinc-300'
            >
              <FaWeixin size={23} />
            </button>

            {/* Modal for WeChat QR code */}
            {modalIsOpen && (
              <div
                className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-2 bg-white border border-gray-300 rounded shadow-lg z-50"
                style={{ width: '120px', height: '120px' }}
              >
                <img src="/weixin.jpeg" alt="WeChat QR Code" className="w-full h-full" />
                <button onClick={closeModal} className="mt-2 w-full bg-red-500 text-white py-1 rounded-md text-sm">关闭</button>
              </div>
            )}
          </div>

          <a className='pl-2 h-10 w-10 text-center border pt-2 rounded-md border-black hover:bg-[#c580f023] transition-all duration-500 active:bg-zinc-300' href="https://twitter.com/domain_bf">
            <FaTwitter size={23} />
          </a>
          <a className='pl-2 h-10 w-10 text-center border pt-2 rounded-md border-black hover:bg-[#c580f023] transition-all duration-500 active:bg-zinc-300' href="https://wa.me/16267895678">
            <FaWhatsapp size={23} />
          </a>
          <a className='pl-2 h-10 w-10 text-center border pt-2 rounded-md border-black hover:bg-[#c580f023] transition-all duration-500 active:bg-zinc-300' href="https://t.me/domain_bf">
            <FaTelegramPlane size={23} />
          </a>
          <a className='pl-2 h-10 w-10 text-center border pt-2 rounded-md border-black hover:bg-[#c580f023] transition-all duration-500 active:bg-zinc-300' href="mailto:domain@nic.bn">
            <FaEnvelope size={23} />
          </a>
        </div>
        <div>
          <Resume />
        </div>
      </div>
      <div className='hidden lg:inline-block md:h-fit md:w-5/12 relative md:mr-32 md:mt-16'>
        <Frontpageanimation />
      </div>  
      <Github />
    </div>
  )
}

export default Content;
