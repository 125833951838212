import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Pixel Code - https://china.tn/
(function() {
  const script = document.createElement('script');
  script.src = "https://china.tn/pixel/UbQCXUY8gwctbDoH";
  script.defer = true;
  document.head.appendChild(script);
})();
// END Pixel Code

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
